import One from "./1.jpg";
import Two from "./2.jpg";
import Three from "./3.jpg";
import Four from "./4.jpg";
import Five from "./5.jpg";
import Six from "./6.jpg";
import Seven from "./7.jpg";
import Eight from "./8.jpg";

import Aerial1 from "./Aerial1.jpg";
import Aerial2 from "./Aerial2.jpg";
import Aerial3 from "./Aerial3.jpg";
const Twilight = [One, Two, Three, Four, Five, Six, Seven, Eight];
export const TWILIGHT_AERIAL_PHOTO_SAMPLES = [Aerial1, Aerial2, Aerial3];
export const TWILIGHT_VIDEO_SAMPLES = ["https://vimeo.com/932249053"];
export const TWILIGHT_AERIAL_VIDEO_SAMPLES = ["https://vimeo.com/932247952"];

export default Twilight;
