import {
  chain,
  every,
  forEach,
  isEmpty,
  isFunction,
  isPlainObject,
  map,
  startCase,
} from "lodash";
import { isInternal } from "./isInternal";
import uplist from "../adapters/uplist";

const removeFalsy = (obj) => {
  if (!obj) return null;

  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v === 0 || !!v)
      .map(([k, v]) => [k, isPlainObject(v) ? removeFalsy(v) : v])
  );
};

const createNotes = (defaultNotes, zone_sku) => {
  const notes = removeFalsy(defaultNotes);
  if (!notes) return {};
  const order_notes = [];
  const order_info_notes = {};
  forEach(notes, (note, key) => {
    let modified_note = note;
    if (key === "order") {
      order_notes.push(`Agent Notes: ${note}`);
    } else if (key === "cat_floorplan") {
      modified_note = `FP on file with modifications: ${note}`;
      const existing_fp_note = order_info_notes["floorplan_notes"] || "";
      order_info_notes.floorplan_notes = existing_fp_note + modified_note;
      order_notes.push(modified_note);
    } else if (key === "virtual_staging") {
      const existing_ph_note = order_info_notes.photo_notes || "";
      modified_note = `Virtual Staging Notes: ${note}`;
      order_info_notes.photo_notes = existing_ph_note + modified_note;
    } else {
      const note_key = `${key}_notes`;
      const existing_note = order_info_notes[note_key] || "";
      order_info_notes[note_key] = existing_note + modified_note;
    }
  });
  if (zone_sku) order_notes.push("Travel Charge Quote Needed");
  return { order_notes: order_notes, order_info_notes };
};

export const submitOrder = async (data) => {
  console.log("❗️ - submitOrder - data:", data);
  const {
    user_id,
    stock_photos,
    address_info,
    print,
    matter_tag_notes,
    notes,
    rest,
    order_info,
    ...other_fields
  } = data;

  const { products, other } = Object.keys(other_fields)
    .filter((key) => other_fields[key])
    .reduce(
      (res, key) => {
        const product_value = other_fields[key];
        const value = product_value?.value ?? product_value;
        res[isNaN(key) ? "other" : "products"][key] = value;
        return res;
      },
      { products: {}, other: {} }
    );
  console.log("❗️ - products", products);
  console.log("❗️ - other", other);

  if (address_info.zone_sku) products[address_info.zone_sku] = true;

  const { order_notes, order_info_notes } = createNotes(
    notes,
    address_info?.zone_sku
  );
  console.log("❗️ - submitOrder - order_info_notes:", order_info_notes);
  console.log("❗️ - submitOrder - order_notes:", order_notes);
  if (!!print) {
    order_info_notes.print_notes = chain(print)
      .flatMap((val, key) => {
        if (every(val, isEmpty)) return [];
        return [
          [`${startCase(key)} Notes:`, ...map(val)].filter(Boolean).join(" "),
        ];
      })
      .tap(
        (array) =>
          order_info_notes?.print_notes &&
          array.unshift(order_info_notes?.print_notes)
      )
      .join("\r\n")
      .value();
  }
  if (matter_tag_notes) {
    order_info_notes.virtual_open_house_notes = [
      order_info_notes.virtual_open_house_notes,
      `Matter Tag Notes: ${matter_tag_notes}`,
    ]
      .filter(Boolean)
      .join("\r\n");
  }

  if (
    other.cat_selected &&
    (other.premium_fp_addons || other.standard_fp_addons)
  ) {
    order_notes.push(
      "Catalogue FP selected with Addons that may need return visit."
    );
  }

  const address = removeFalsy({
    unit_number: address_info.unit_number,
    street_number: address_info.street_number,
    street_name: address_info.street_name,
    street_suffix: address_info.street_suffix,
    region_id: address_info.region,
    latitude: isFunction(address_info.latlng?.lat)
      ? address_info.latlng?.lat()
      : address_info.latlng?.lat,
    longitude: isFunction(address_info.latlng?.lng)
      ? address_info.latlng?.lng()
      : address_info.latlng?.lng,
  });
  console.log("❗️ - address", address);

  const order = {
    products,
    order_notes: order_notes.join("\r\n"),
    address,
    order_info: removeFalsy({ ...order_info_notes, ...order_info }),
    stock_photos: !!stock_photos.length ? stock_photos : undefined,
    user_id,
    internal: isInternal(),
  };
  console.log("❗️ - order", order);
  // return Promise.resolve();
  return uplist.post("/orders", { order });
};
